import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

import { IoFileTraySharp } from "react-icons/io5";
import { AiFillFile } from "react-icons/ai";

const Container = styled.div`
  position: relative;
  height: 100%;
  border: dashed 2px silver;
  border-radius: 4px;
  padding: 25px 7px;
  text-align: center;
  box-sizing: border-box;
  background-color: white;
  transition: all 0.2s ease-in-out;
`;

const FileContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%)
    rotate(${(props) => (props.isDragActive ? "0deg" : "20deg")});
  margin-top: -20px;
  opacity: ${(props) => (props.isDragActive ? "1" : "0")};

  svg {
    width: ${(props) => (props.isDragActive ? "80px" : "0px")};
    transition: width 0.2s cubic-bezier(0.175, 0.985, 0.32, 1.275);
  }

  transition: transform 0.3s cubic-bezier(0.175, 0.985, 0.32, 1.275),
    opacity 0.15s ease-in-out 0.15s;
`;

const DragNDropInput = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop, accept: ".jar,.exe,.dll,.msi,.msix,.zip" });

  const onMouseEnter = () => {
    const bt = document.getElementById("add-button");
    const sp = document.getElementById("drag-span");
    if (bt) bt.style.display = "block";
    if (sp && acceptedFiles.length === 0) sp.style.display = "none";
  };

  const onMouseLeave = () => {
    const bt = document.getElementById("add-button");
    const sp = document.getElementById("drag-span");
    if (bt) bt.style.display = "none";
    if (sp) sp.style.display = "block";
  };

  return (
    <Container
      {...getRootProps()}
      isDragActive={isDragActive}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <input {...getInputProps()} />
      <div>
        <FileContainer isDragActive={isDragActive}>
          <AiFillFile size={70} color={"#ff9100"} />
        </FileContainer>
        <FileContainer
          isDragActive={!isDragActive && acceptedFiles.length === 1}
        >
          <AiFillFile
            size={70}
            color={"#0e5b82"}
            style={{ height: 120, width: 100 }}
          />
        </FileContainer>

        <IoFileTraySharp
          size={100}
          color={"grey"}
          style={{
            opacity: isDragActive || acceptedFiles.length === 0 ? 1 : 0,
          }}
        />
      </div>

      {isDragActive ? (
        <span style={{ color: "grey" }}>Drop the file here</span>
      ) : (
        <>
          <span id="drag-span" style={{ color: "grey", cursor: "pointer" }}>
            {acceptedFiles.length === 0
              ? "Drag the file here..."
              : acceptedFiles[0].name}
          </span>
          {acceptedFiles.length === 0 && (
            <Button
              id="add-button"
              variant="contained"
              style={{
                boxShadow: "none",
                display: "none",
                margin: "0px auto",
                bottom: "5px",
              }}
            >
              Add File
            </Button>
          )}
        </>
      )}
    </Container>
  );
};

export default DragNDropInput;
